




































































































































































import { defineComponent } from "@vue/composition-api";
import {
	mapActions as mapPiniaActions,
	mapState as mapPiniaState,
	mapWritableState as mapPiniaWritableState,
} from "pinia";
import { useCoachStore } from "@/pinia/coach";
import { useLocationStore } from "@/pinia/location";
import { useMemberStore } from "@/pinia/member";
import { getIconName } from "@/utils/images";

export default defineComponent({
	data() {
		return {
			loading: false,
		};
	},
	computed: {
		...mapPiniaState(useMemberStore, {
			members: "members",
			membersLoading: ({ state: { loading } }) => loading,
			membersDirty: ({ state: { dirty } }) => dirty,
		}),
		...mapPiniaWritableState(useMemberStore, {
			filters: "filters",
			membersState: "state",
		}),
		...mapPiniaState(useLocationStore, {
			location: "location",
			locationBySlug: "locationBySlug",
			locations: "locationsSortedByName",
		}),
		...mapPiniaState(useCoachStore, {
			allCoaches: "coachesSortedByName",
			coachesByLocationId: "coachesByLocationIdSortedByName",
		}),
	},
	watch: {
		filters: {
			async handler() {
				if (this.membersDirty === undefined) {
					await this.setMembersState({
						dirty: false,
					});
				} else {
					await this.setMembersState({
						dirty: true,
						endReached: false,
						page: 1,
					});
					await this.getMembersDebounced();
				}
			},
			deep: true,
		},
	},
	async created() {
		this.loading = true;
		await this.initCoach();
		await this.initLocation();
		await this.initMembers();
		await this.resetMembersState();
		await this.setFilters({
			vestiging: this.location?.slug ?? "all",
		});
		await this.getMembers();
		await this.setInfiniteScroll();
		this.loading = false;
	},
	async destroyed() {
		await this.resetFilters();
		await this.removeInfiniteScroll();
	},
	methods: {
		...mapPiniaActions(useMemberStore, {
			initMembers: "init",
			getMembers: "get",
			getMembersDebounced: "debouncedGet",
			setFilters: "setFilters",
			resetFilters: "resetFilters",
			setMembersState: "setState",
			resetMembersState: "resetState",
		}),
		...mapPiniaActions(useCoachStore, {
			initCoach: "init",
		}),
		...mapPiniaActions(useLocationStore, {
			initLocation: "init",
			getLocationPreference: "getPreference",
		}),
		async setInfiniteScroll() {
			window.onscroll = async () => {
				const OFFSET = 20;
				const bottomOfWindow =
					document.documentElement.scrollTop + window.innerHeight >=
					document.documentElement.offsetHeight - OFFSET;
				if (bottomOfWindow) await this.getMembers();
			};
		},
		async removeInfiniteScroll() {
			window.onscroll = null;
		},
		coaches() {
			if (this.filters.vestiging === "all") {
				return this.allCoaches;
			} else {
				const locationId = this.locationBySlug(this.filters?.vestiging)?.id;

				if (!locationId) {
					return this.allCoaches;
				}

				return this.coachesByLocationId(locationId);
			}
		},
		getIconName,
	},
});
